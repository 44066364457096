@import "styles/fonts.scss";
@import "styles/variables";

body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-light;
}

code {
  font-family: $font-regular;
}

a {
  color: $black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
