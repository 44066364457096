// Colors
$red-bci: #ff0000;
$red-light: #ffe8e8;

$grey-light: #f7f7f7;
$grey-regular: #e8e8e8;
$grey-cool-bci: #808080;
$grey-text: #6a6a73;

$black: #454550;

$blue: #215d95;
$blue-light: #ecf7ff;

// Fonts
$font-regular: "Roboto", sans-serif;
